import React from "react";
import FilterByForDashboard from "../FilterByForDashboard";
import AverageAmountPerUse from "./usageComponents/AverageAmountPerUse";
import ChartDivider from "../ChartDivider";
import ConsumptionSlope from "./usageComponents/ConsumptionSlope";
import AverageWeeklyFrequency from "./usageComponents/AverageWeeklyFrequency";
import AverageDailyFrequency from "./usageComponents/AverageDailyFrequency";

const Usage = () => {
  return (
    <div className="row">
      {/* Filter By Component */}
      <FilterByForDashboard />
      <div className="col-9" style={{ padding: "0px 5%" }}>
        <AverageAmountPerUse />
        <ChartDivider />
        <ConsumptionSlope />
        <ChartDivider />
        <AverageWeeklyFrequency />
        <ChartDivider />
        <AverageDailyFrequency />
        <hr style={{ margin: "2rem 0" }} />
      </div>
    </div>
  );
};
export default Usage;
