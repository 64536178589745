import { authenticateUser } from "../../services/auth/login";
import { LOGIN_SUCCESS, LOGIN_FAILURE } from "../constants";

export const login = (credentials, navigate, setLoading) => (dispatch) => {
  setLoading(true);
  authenticateUser(credentials)
    .then((response) => {
      const { token, user_info } = response.data;
      // Dispatch login success action
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token, user_info },
      });
      if (response.data.success === 1) {
        setLoading(false);
        navigate("/dashboard");
        return response.data;
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_FAILURE,
        payload: error.message,
      });
      throw error;
    });
};
