import React, { useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import Usage from "../dashboardTabs/usage/Usage";
import Occasions from "../dashboardTabs/occasions/Occasions";
const Dashboard = (props) => {
  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <TabView>
              <TabPanel header="USAGE" className="tabStyle">
                <Usage />
              </TabPanel>
              <TabPanel header="OCCASIONS">
                <Occasions />
              </TabPanel>
              <TabPanel header="H/M/L">
                <div className="row">
                  <div>H/M/L</div>
                </div>
              </TabPanel>
              <TabPanel header="NEEDS">
                <div className="row">
                  <div>NEEDS</div>
                </div>
              </TabPanel>
              <TabPanel header="LOYALTY">
                <div className="row">
                  <div>LOYALTY</div>
                </div>
              </TabPanel>
              <TabPanel header="EXPERIENCE">
                <div className="row">
                  <div>EXPERIENCE</div>
                </div>
              </TabPanel>
              <TabPanel header="USER PROFILE">
                <div className="row">
                  <div>USER PROFILE</div>
                </div>
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
