import React, { useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";

const FilterBy = () => {
  const [activeOption, setActiveOption] = useState("FILTERBY");
  const categories = [
    { name: "PRODUCT", key: "A" },
    { name: "DEVICE", key: "M" },
    { name: "APPLIANCE", key: "P" },
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[1]);
  const appliancelist = [
    { name: "All", key: "A" },
    { name: "DISHWASHER", key: "D" },
    { name: "WASHING MACHINE", key: "W" },
  ];
  const [selectedAppliancelist, setSelectedApplianceList] = useState([
    appliancelist[1],
  ]);
  const onCategoryChange = (e) => {
    let _selectedAppliancelist = [...selectedAppliancelist];

    if (e.checked) _selectedAppliancelist.push(e.value);
    else
      _selectedAppliancelist = _selectedAppliancelist.filter(
        (appliance) => appliance.key !== e.value.key,
      );

    setSelectedApplianceList(_selectedAppliancelist);
  };

  const brandlist = [
    { name: "BRITANNIA", key: "B" },
    { name: "PARLE G", key: "P" },
    { name: "OREO", key: "O" },
    { name: "SUNFEAST", key: "S" },
  ];
  const [selectedBrandlist, setSelectedBrandList] = useState([brandlist[1]]);
  const onBrandChange = (e) => {
    let _selectedBrandlist = [...selectedBrandlist];

    if (e.checked) _selectedBrandlist.push(e.value);
    else
      _selectedBrandlist = _selectedBrandlist.filter(
        (brand) => brand.key !== e.value.key,
      );

    setSelectedBrandList(_selectedBrandlist);
  };

  const formlist = [
    { name: "ALL", key: "A" },
    { name: "LIQUID", key: "L" },
    { name: "LIQUID/FOAM PUMP", key: "LF" },
    { name: "SPRAY", key: "S" },
  ];
  const [selectedFormlist, setSelectedFormList] = useState([formlist[1]]);
  const onFormChange = (e) => {
    let _selectedFormlist = [...selectedFormlist];

    if (e.checked) _selectedFormlist.push(e.value);
    else
      _selectedFormlist = _selectedFormlist.filter(
        (form) => form.key !== e.value.key,
      );

    setSelectedFormList(_selectedFormlist);
  };

  const genderlist = [
    { name: "ALL", key: "A" },
    { name: "MALE", key: "M" },
    { name: "FEMALE", key: "F" },
    { name: "NON-BINARY", key: "NB" },
  ];
  const [selectedGenderlist, setSelectedGenderList] = useState([genderlist[1]]);
  const onGenderChange = (e) => {
    let _selectedGenderlist = [...selectedFormlist];

    if (e.checked) _selectedGenderlist.push(e.value);
    else
      _selectedGenderlist = _selectedGenderlist.filter(
        (gender) => gender.key !== e.value.key,
      );

    setSelectedGenderList(_selectedGenderlist);
  };
  const ageGrouplist = [
    { name: "ALL", key: "A" },
    { name: "UNDER 18 YEARS", key: "18" },
    { name: "18-24", key: "24" },
    { name: "25-29", key: "29" },
    { name: "30-34", key: "34" },
    { name: "35-39", key: "39" },
    { name: "40-44", key: "44" },
  ];
  const [selectedAgeGrouplist, setSelectedAgeGroupList] = useState([
    ageGrouplist[1],
  ]);
  const onAgeGroupChange = (e) => {
    let _selectedAgeGrouplist = [...selectedAgeGrouplist];

    if (e.checked) _selectedAgeGrouplist.push(e.value);
    else
      _selectedAgeGrouplist = _selectedAgeGrouplist.filter(
        (agegroup) => agegroup.key !== e.value.key,
      );

    setSelectedAgeGroupList(_selectedAgeGrouplist);
  };
  const segmentlist = [
    { name: "ALL", key: "A" },
    { name: "CM-DELAYER", key: "CMD" },
    { name: "CM-MAINTENANCE", key: "CMM" },
    { name: "CM-NA", key: "CMN" },
  ];
  const [selectedSegmentlist, setSelectedSegmentList] = useState([
    segmentlist[1],
  ]);
  const onSegmentChange = (e) => {
    let _selectedSegmentlist = [...selectedSegmentlist];

    if (e.checked) _selectedSegmentlist.push(e.value);
    else
      _selectedSegmentlist = _selectedSegmentlist.filter(
        (segment) => segment.key !== e.value.key,
      );

    setSelectedSegmentList(_selectedSegmentlist);
  };
  const handleFilterAndCompare = (option) => {
    setActiveOption(option);
  };
  return (
    <>
      {activeOption === "FILTERBY" ? (
        <div className="col-3" style={{ borderRight: "1px solid #dee2e6" }}>
          <div className="filter-header-row">
            <div className="col-6">
              <Button
                className={`p-button-text me-2  ${activeOption === "FILTERBY" ? "active-button" : "inactive-button"} br-c`}
                outlined
                onClick={() => handleFilterAndCompare("FILTERBY")}
                label="FILTER BY"
                style={{ width: "130px" }}
              />
            </div>
            <div className="col-6">
              <Button
                className={`p-button-text me-2 ${activeOption === "COMPAREBY" ? "active-button" : "inactive-button"} br-c`}
                outlined
                onClick={() => handleFilterAndCompare("COMPAREBY")}
                label="COMPARE BY"
                style={{ width: "130px" }}
              />
            </div>
          </div>
          <div className="flex flex-column gap-3 text-start mt-3">
            <span className="filter-label">SELECT</span>
            {categories.map((category) => {
              return (
                <div key={category.key} className="pt-2 pb-2">
                  <RadioButton
                    inputId={category.key}
                    name="category"
                    value={category}
                    onChange={(e) => setSelectedCategory(e.value)}
                    checked={selectedCategory.key === category.key}
                  />
                  <label htmlFor={category.key} className="ps-2">
                    {category.name}
                  </label>
                </div>
              );
            })}
          </div>
          <Panel
            className="mt-2 text-start"
            header="BY APPLIANCE"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
          >
            <div className="flex flex-column gap-3">
              {appliancelist.map((appliance) => {
                return (
                  <div
                    key={appliance.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={appliance.key}
                      name="category"
                      value={appliance}
                      onChange={onCategoryChange}
                      checked={selectedAppliancelist.some(
                        (item) => item.key === appliance.key,
                      )}
                    />
                    <label htmlFor={appliance.key} className="ml-2 ps-2">
                      {appliance.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY BRAND"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {brandlist.map((brand) => {
                return (
                  <div
                    key={brand.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={brand.key}
                      name="category"
                      value={brand}
                      onChange={onBrandChange}
                      checked={selectedBrandlist.some(
                        (item) => item.key === brand.key,
                      )}
                    />
                    <label htmlFor={brand.key} className="ml-2 ps-2">
                      {brand.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY FORM"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {formlist.map((form) => {
                return (
                  <div
                    key={form.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={form.key}
                      name="category"
                      value={form}
                      onChange={onFormChange}
                      checked={selectedFormlist.some(
                        (item) => item.key === form.key,
                      )}
                    />
                    <label htmlFor={form.key} className="ml-2 ps-2">
                      {form.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY GENDER"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {genderlist.map((gender) => {
                return (
                  <div
                    key={gender.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={gender.key}
                      name="category"
                      value={gender}
                      onChange={onGenderChange}
                      checked={selectedGenderlist.some(
                        (item) => item.key === gender.key,
                      )}
                    />
                    <label htmlFor={gender.key} className="ml-2 ps-2">
                      {gender.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY AGE GROUP"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {ageGrouplist.map((agegroup) => {
                return (
                  <div
                    key={agegroup.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={agegroup.key}
                      name="category"
                      value={agegroup}
                      onChange={onAgeGroupChange}
                      checked={selectedAgeGrouplist.some(
                        (item) => item.key === agegroup.key,
                      )}
                    />
                    <label htmlFor={agegroup.key} className="ml-2 ps-2">
                      {agegroup.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              AND
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY SEGMENT"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {segmentlist.map((segment) => {
                return (
                  <div
                    key={segment.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={segment.key}
                      name="category"
                      value={segment}
                      onChange={onSegmentChange}
                      checked={selectedSegmentlist.some(
                        (item) => item.key === segment.key,
                      )}
                    />
                    <label htmlFor={segment.key} className="ml-2 ps-2">
                      {segment.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center"></Divider>
          <div className="flex align-items-center gap-2 managed-row">
            <Button
              label="RESET"
              className="p-button-text me-2 resetButton br-c fw-600"
              style={{ width: "120px", color: "gray" }}
            ></Button>
            <Button
              label="APPLY"
              className="p-button-text me-2  active-button br-c fw-600"
              style={{ width: "120px" }}
            ></Button>
          </div>
        </div>
      ) : (
        <div className="col-3" style={{ borderRight: "1px solid #dee2e6" }}>
          <div className="filter-header-row">
            <div className="col-6">
              <Button
                className={`p-button-text me-2  ${activeOption === "FILTERBY" ? "active-button" : "inactive-button"} br-c`}
                outlined
                onClick={() => handleFilterAndCompare("FILTERBY")}
                label="FILTER BY"
                style={{ width: "130px" }}
              />
            </div>
            <div className="col-6">
              <Button
                className={`p-button-text me-2 ${activeOption === "COMPAREBY" ? "active-button" : "inactive-button"} br-c`}
                outlined
                onClick={() => handleFilterAndCompare("COMPAREBY")}
                label="COMPARE BY"
                style={{ width: "130px" }}
              />
            </div>
          </div>
          <div className="flex flex-column gap-3 text-start mt-3">
            <span className="filter-label">SELECT</span>
            {categories.map((category) => {
              return (
                <div key={category.key} className="pt-2 pb-2">
                  <RadioButton
                    inputId={category.key}
                    name="category"
                    value={category}
                    onChange={(e) => setSelectedCategory(e.value)}
                    checked={selectedCategory.key === category.key}
                  />
                  <label htmlFor={category.key} className="ps-2">
                    {category.name}
                  </label>
                </div>
              );
            })}
          </div>
          <Panel
            className="mt-2 text-start"
            header="BY APPLIANCE"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
          >
            <div className="flex flex-column gap-3">
              {appliancelist.map((appliance) => {
                return (
                  <div
                    key={appliance.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={appliance.key}
                      name="category"
                      value={appliance}
                      onChange={onCategoryChange}
                      checked={selectedAppliancelist.some(
                        (item) => item.key === appliance.key,
                      )}
                    />
                    <label htmlFor={appliance.key} className="ml-2 ps-2">
                      {appliance.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              OR
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY BRAND"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {brandlist.map((brand) => {
                return (
                  <div
                    key={brand.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={brand.key}
                      name="category"
                      value={brand}
                      onChange={onBrandChange}
                      checked={selectedBrandlist.some(
                        (item) => item.key === brand.key,
                      )}
                    />
                    <label htmlFor={brand.key} className="ml-2 ps-2">
                      {brand.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              OR
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY FORM"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {formlist.map((form) => {
                return (
                  <div
                    key={form.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={form.key}
                      name="category"
                      value={form}
                      onChange={onFormChange}
                      checked={selectedFormlist.some(
                        (item) => item.key === form.key,
                      )}
                    />
                    <label htmlFor={form.key} className="ml-2 ps-2">
                      {form.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              OR
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY GENDER"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {genderlist.map((gender) => {
                return (
                  <div
                    key={gender.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={gender.key}
                      name="category"
                      value={gender}
                      onChange={onGenderChange}
                      checked={selectedGenderlist.some(
                        (item) => item.key === gender.key,
                      )}
                    />
                    <label htmlFor={gender.key} className="ml-2 ps-2">
                      {gender.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              OR
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY AGE GROUP"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {ageGrouplist.map((agegroup) => {
                return (
                  <div
                    key={agegroup.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={agegroup.key}
                      name="category"
                      value={agegroup}
                      onChange={onAgeGroupChange}
                      checked={selectedAgeGrouplist.some(
                        (item) => item.key === agegroup.key,
                      )}
                    />
                    <label htmlFor={agegroup.key} className="ml-2 ps-2">
                      {agegroup.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center">
            <span
              className="filter-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              OR
            </span>
          </Divider>
          <Panel
            className="mt-2 text-start"
            header="BY SEGMENT"
            toggleable
            expandIcon="pi pi-angle-up"
            collapseIcon="pi pi-angle-down"
            collapsed={true}
          >
            <div className="flex flex-column gap-3">
              {segmentlist.map((segment) => {
                return (
                  <div
                    key={segment.key}
                    className="flex align-items-center pt-2 pb-2"
                  >
                    <Checkbox
                      inputId={segment.key}
                      name="category"
                      value={segment}
                      onChange={onSegmentChange}
                      checked={selectedSegmentlist.some(
                        (item) => item.key === segment.key,
                      )}
                    />
                    <label htmlFor={segment.key} className="ml-2 ps-2">
                      {segment.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Panel>
          <Divider align="center"></Divider>
          <div className="flex align-items-center gap-2 managed-row">
            <Button
              label="RESET"
              className="p-button-text me-2 resetButton br-c fw-600"
              style={{ width: "120px", color: "gray" }}
            ></Button>
            <Button
              label="APPLY"
              className="p-button-text me-2  active-button br-c fw-600"
              style={{ width: "120px" }}
            ></Button>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterBy;
