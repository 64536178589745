import "./App.css";
import Login from "./views/auth/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Layout from "./views/layout/Layout";
import Settings from "./views/layout/Settings";
import Dashboard from "./components/mainDashboard/Dashboard";
import ProtectedRoute from "../src/utils/auth/ProtectedRoute";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Route for login, prevent access if already authenticated */}
          <Route
            path="/login"
            element={
              <ProtectedRoute isLoginRoute={true}>
                <Login />
              </ProtectedRoute>
            }
          />

          {/* Protected routes for authenticated users */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <Layout renderReports />
              </ProtectedRoute>
            }
          />

          {/* Default redirect to dashboard for authenticated users, or to login */}
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* Catch-all route for any undefined routes, redirects to login */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
