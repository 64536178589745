import React, { useState, useEffect } from "react";
import { Menubar } from "primereact/menubar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "../../assets/css/dashboard.css"; // Custom styles
import "../../assets/css/primecustomdashboard.css";
import Header from "../../components/header/Header";
import tb_logo from "../../assets/images/tb_logo.png";
import Reports from "../reports/Reports"; // Ensure this is used correctly

const Layout = ({ renderReports }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const handleMenuItemClick = (path) => {
    navigate(path);
    setPath(path);
  };

  const menuItems = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: () => handleMenuItemClick("/dashboard"),
      className: path === "/dashboard" ? "p-highlighter" : "",
    },
    {
      label: "Login",
      icon: "pi pi-fw pi-sign-in",
      command: () => handleMenuItemClick("/login"),
      className: path === "/login" ? "p-highlighter" : "",
    },
    {
      label: "Reports",
      icon: "pi pi-fw pi-chart-bar",
      command: () => handleMenuItemClick("/reports"),
      className: path === "/reports" ? "p-highlighter" : "",
    },
    {
      label: "Settings",
      icon: "pi pi-fw pi-cog",
      command: () => handleMenuItemClick("/dashboard/settings"),
      className: path === "/dashboard/settings" ? "p-highlighter" : "",
    },
    // Add more menu items as needed
  ];

  return (
    <div className="d-flex vh-100">
      <div className="position-fixed left-sidebar">
        <img src={tb_logo} className="image-fluid logo_dash" alt="Logo" />
        <Menubar model={menuItems} className="custom-menubar" />
      </div>
      <div className="flex-grow-1 d-flex flex-column main-content">
        <Header renderReports={renderReports} /> {/* Pass the prop if needed */}
        {renderReports ? <Reports /> : <Outlet />} {/* Conditional rendering */}
      </div>
    </div>
  );
};

export default Layout;
