import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from "../utils/constants";

const initialState = {
  token: null,
  user: null,
  error: null,
  isAuthenticated: false, // Initialize to false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user_info,
        isAuthenticated: true, // Set to true on successful login
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false, // Set to false on login failure
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false, // Reset to false on logout
      };
    default:
      return state;
  }
};

export default authReducer;
