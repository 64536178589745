import React, { useState } from "react";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Chart } from "primereact/chart";
import { Button } from "primereact/button";
import "../../assets/css/dashboard.css"; // Ensure your CSS file is correctly imported
import { Dropdown } from "primereact/dropdown";
import FilterBy from "../../components/reports/FilterBy";
const Reports = (props) => {
  const options = [
    { label: "USE", value: "use" },
    { label: "DAY", value: "day" },
  ];

  const [selectedOption, setSelectedOption] = useState("day");
  const [activeButton, setActiveButton] = useState("HOW OFTEN");
  const dailyAverageData = {
    labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
    datasets: [
      {
        label: "MALE",
        data: [17.46, 17.62, 17.7, 16.29, 18.35, 18.4, 20.56],
        backgroundColor: "orange",
      },
      {
        label: "FEMALE",
        data: [17.46, 17.62, 17.7, 16.29, 18.35, 18.4, 20.56],
        backgroundColor: "blue",
      },
      {
        label: "FEMALE",
        data: [17.46, 17.62, 17.7, 16.29, 18.35, 18.4, 20.56],
        backgroundColor: "red",
      },
    ],
  };

  const weekdayWeekendData = {
    labels: ["Weekday Mon-Fri", "Weekend Sat-Sun"],
    datasets: [
      {
        label: "# of Uses Per Day of Week - Weekday Vs. Weekend Daily Average",
        data: [17.46, 28.62],
        backgroundColor: "orange",
      },
      {
        label: "# of Uses Per Day of Week - Weekday Vs. Weekend Daily Average2",
        data: [17.46, 28.62],
        backgroundColor: "blue",
      },
      {
        label: "# of Uses Per Day of Week - Weekday Vs. Weekend Daily Average2",
        data: [17.46, 28.62],
        backgroundColor: "red",
      },
    ],
  };

  const timeOfDayData = {
    labels: [
      "12AM-4AM",
      "4AM-8AM",
      "8AM-12PM",
      "12PM-4PM",
      "4PM-8PM",
      "8PM-12AM",
    ],
    datasets: [
      {
        label: "% of Uses Per Time of Day - Average",
        data: [17.46, 17.62, 17.7, 16.29, 18.35, 18.4],
        backgroundColor: "orange",
      },
      {
        label: "% of Uses Per Time of Day - Average2",
        data: [17.46, 17.62, 17.7, 16.29, 18.35, 18.4],
        backgroundColor: "orange",
      },
      {
        label: "% of Uses Per Time of Day - Average3",
        data: [17.46, 17.62, 17.7, 16.29, 18.35, 18.4],
        backgroundColor: "orange",
      },
    ],
  };

  const totalAverageData = {
    labels: ["# OF USES PER DAY - TOTAL AVERAGE"],
    datasets: [
      {
        label: "MALE",
        data: [12.4],
        backgroundColor: "#FF9800",
      },
      {
        label: "FEMALE",
        data: [10.0],
        backgroundColor: "#0089FA",
      },
    ],
  };

  const dailyAverageOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const weekdayWeekendOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const timeOfDayOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const totalAverageOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  return (
    <div className="container-fluid page-wrapper">
      <div className="row">
        <div className="col-12 col-md-12">
          <Card>
            <TabView>
              <TabPanel header="SENSOR CHARTS" className="tabStyle">
                <div className="d-flex align-items-center justify-content-center managed-row">
                  <Button
                    className={`p-button-text me-2 me-md-3 ${activeButton === "HOW OFTEN" ? "active-button" : "inactive-button"} br-c`}
                    outlined
                    onClick={() => handleButtonClick("HOW OFTEN")}
                    label="HOW OFTEN"
                  />
                  <Button
                    className={`p-button-text me-2 me-md-3 ${activeButton === "HOW LONG" ? "active-button" : "inactive-button"} br-c`}
                    outlined
                    onClick={() => handleButtonClick("HOW LONG")}
                    label="HOW LONG"
                  />
                </div>
                <div className="row" style={{ borderTop: "1px solid #dee2e6" }}>
                  {/* Filter By Component */}
                  <FilterBy />
                  {/* <div className="col-1"></div> */}
                  <div className="col-9" style={{ padding: "0px 5%" }}>
                    <div className="fs-14 text-start mt-3">
                      <strong>
                        # OF USES PER{" "}
                        <Dropdown
                          value={selectedOption}
                          options={options}
                          onChange={(e) => setSelectedOption(e.value)}
                          style={{
                            width: "auto",
                            display: "inline-flex",
                            color: "#002133",
                            padding: "0",
                          }}
                          className="form-control fw-600"
                          id="hmSelect"
                        />{" "}
                        - TOTAL AVERAGE
                      </strong>
                    </div>
                    <div className="col-12">
                      <Chart
                        type="bar"
                        data={totalAverageData}
                        options={totalAverageOptions}
                      />
                      <div className="my-table data-table">
                        <div className="table-responsive">
                          <table className="table text-start">
                            <thead>
                              <tr className="my-table table-first-row-border">
                                <th></th>
                                {totalAverageData.labels.map((label) => (
                                  <th className="text-center" key={label}>
                                    {label}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>AVERAGE</td>
                                {totalAverageData.datasets[0].data.map(
                                  (data, index) => (
                                    <td className="text-center" key={index}>
                                      {data}
                                    </td>
                                  ),
                                )}
                              </tr>
                              <tr>
                                <td># of Participants</td>
                                {totalAverageData.labels.map((_, index) => (
                                  <td className="text-center" key={index}>
                                    57
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <strong className="fs-14 float-start">
                        # OF USES PER DAY OF WEEK - DAILY AVERAGE
                      </strong>
                      <Chart
                        type="bar"
                        data={dailyAverageData}
                        options={dailyAverageOptions}
                      />
                      <div className="my-table data-table">
                        <div className="table-responsive">
                          <table className="table text-start">
                            <thead>
                              <tr className="table-first-row-border">
                                <th></th>
                                {dailyAverageData.labels.map((label) => (
                                  <th key={label}>{label}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>AVERAGE</td>
                                {dailyAverageData.datasets[0].data.map(
                                  (data, index) => (
                                    <td key={index}>{data}</td>
                                  ),
                                )}
                              </tr>
                              <tr>
                                <td># of Participants</td>
                                {dailyAverageData.labels.map((_, index) => (
                                  <td key={index}>57</td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <strong className="fs-14 float-start">
                        # OF USES PER DAY OF WEEK - WEEKDAY VS. WEEKEND DAILY
                        AVERAGE
                      </strong>
                      <Chart
                        type="bar"
                        data={weekdayWeekendData}
                        options={weekdayWeekendOptions}
                      />
                      <div className="my-table data-table">
                        <div className="table-responsive">
                          <table className="table text-start">
                            <thead>
                              <tr className="table-first-row-border">
                                <th></th>
                                {weekdayWeekendData.labels.map((label) => (
                                  <th key={label}>{label}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>AVERAGE</td>
                                {weekdayWeekendData.datasets[0].data.map(
                                  (data, index) => (
                                    <td key={index}>{data}</td>
                                  ),
                                )}
                              </tr>
                              <tr>
                                <td># of Participants</td>
                                {weekdayWeekendData.labels.map((_, index) => (
                                  <td key={index}>57</td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <Chart
                        type="bar"
                        data={timeOfDayData}
                        options={timeOfDayOptions}
                      />
                      <div className="my-table data-table">
                        <div className="table-responsive">
                          <table className="table text-start">
                            <thead>
                              <tr className="table-first-row-border">
                                <th></th>
                                {timeOfDayData.labels.map((label) => (
                                  <th key={label}>{label}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>AVERAGE</td>
                                {timeOfDayData.datasets[0].data.map(
                                  (data, index) => (
                                    <td key={index}>{data}</td>
                                  ),
                                )}
                              </tr>
                              <tr>
                                <td># of Participants</td>
                                {timeOfDayData.labels.map((_, index) => (
                                  <td key={index}>57</td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Reports;
