import React, { useState } from "react";
import { Button } from "primereact/button";
import "../../assets/css/dashboard.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const handleButtonClick = (buttonLabel) => {
    // Navigate based on buttonLabel
    if (buttonLabel === "DASHBOARD") {
      navigate("/dashboard"); // Navigate to dashboard
    } else if (buttonLabel === "REPORTS") {
      navigate("/reports");
    }
  };
  return (
    <>
      <div className="d-flex align-items-center p-2 border-bottom position-fixed top-header">
        <div className="flex-grow-1">
          <p className="mb-0 text-start ps-2">P&G Connected Research</p>
        </div>
        <div className="d-flex align-items-center">
          <Button icon="pi pi-bell" className="p-button-text" />
          <Button icon="pi pi-user" className="p-button-text" label="John D" />
        </div>
      </div>
      <div className="d-flex p-1 border-bottom position-fixed second-header">
        <div className="flex-grow-1 ms-4 text-start flex-wrap">
          <Button
            outlined
            className={`me-2 me-md-3 ${location.pathname === "/dashboard" ? "active-button" : ""} br-c`}
            label="DASHBOARD"
            onClick={() => handleButtonClick("DASHBOARD")}
          />
          <Button
            outlined
            className={`me-2 me-md-3 ${location.pathname === "/reports" ? "active-button" : ""} br-c`}
            label="REPORTS"
            onClick={() => handleButtonClick("REPORTS")}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
